import React from "react";
import Links from "./components/Links";

const App: React.FC = () => {
  return (
    <div>
      {/* <Dashboard /> */}
      <div>
        <Links />
      </div>
    </div>
  );
};

export default App;
